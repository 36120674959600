.products{
    padding-top: 100px;
    padding-bottom:100px;
}
.products-heading{
    color:teal;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.product-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    background-color: #F5F5DC;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }


  
  .product-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }
  
  .product-name {
    margin: 0 0 5px 0;
    font-family: "Oxygen", Sans-serif;
  }
  
  .key-benefits {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
 
  