.footer {
    background-color: #1A1A4B;
    color: white;
    padding: 20px 0;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer .service_icon {
    width: 30px;
    transition: transform 0.3s;
  }
  
  .footer .service_icon:hover {
    transform: scale(1.1);
  }
  
  .footer h5 {
    color: gold;
  }
  
  .footer .address, .footer .mobile {
    margin-bottom: 10px;
  }
  .footer-icon{
      transition: transform 0.3s; 
    }
  .footer-icon:hover {
      transform: scale(1.5);
    }
  
