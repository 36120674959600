.navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: fixed;
    width: 100%; 
    background-color: rgba(0, 0, 51, 0.7) !important;
    top:0;
    z-index: 9;
    right: 0;
  }
  
  .nav-link {
    color:  #CCCCCC !important;
    font-size: 17px !important;
  }
  .nav-link:hover{
  color:#FFFF00!important ;
  }
  .nav-link.active{
  color:#FFFF00!important ;
  }
  
  
  .logo-image {
    border-radius: 50%;
  }
  
  @media (max-width: 1300px) { 
    .navbar {
      width: 100%; 
    }
  }
  .navbar-toggler-icon{
    background-color: aliceblue;
  }
  .navbar-toggler{
    background-color: aliceblue !important;
  }